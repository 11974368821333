import React from 'react';
import "./About.css";
import aboutImg from "../../images/about-img.jpg";

const About = () => {
  return (
    <section className='about'>
      <div className='container'>
        <div className='section-title'>
          <h2>About</h2>
        </div>

        <div className='about-content grid'>
          <div className='about-img'>
            <img src = {aboutImg} alt = "" />
          </div>
          <div className='about-text'>
            <h2 className='about-title fs-26 ls-1'>About Pirtix Books</h2>
            <p className='fs-17'>At Pitrix Books, we're passionate about books and the knowledge they bring to the world. Our mission is to provide a seamless and engaging experience for book enthusiasts to explore information about their favorite titles. With just a simple search, you can discover details about a book's title, author, total editions, first published date, and even dive into its summary.</p>
            <p className='fs-17'>We believe in the power of reading and the stories that connect us. Whether you're a student, a casual reader, or an avid book collector, Pitrix Books is your hub to uncover the essence of each book's journey. Our platform is designed to make your exploration effortless and enjoyable, fostering a deeper connection to the literary world. Join us in celebrating the magic of books. Let Pitrix Books be your companion in the quest for knowledge, imagination, and the stories that enrich our lives.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
